<template>
  <div class="footer-follow mb-2">
    <div class="mb-2 text-center">
      <span>FOLLOW US</span>
    </div>
    <ul class="flex justify-center">
      <li
        v-for="(iconItem, index) in listFollow"
        class="footer-follow__icon"
        :key="index"
      >
        <a target="_blank" :href="iconItem.link" :aria-label="iconItem.name">
          <i :class="['fab', iconItem.socialIcon]"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FooterFollowUs",
  props: {
    listFollow: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./FooterFollowUs.scss";
a {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
.footer-follow__icon {
  i {
    font-size: 32px;
  }
}
</style>
